import styled from '@emotion/styled';
import { theme } from '../../../styles';

export const ArticleSectionContainer = styled.div``;

export const HeroArticleContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.primaryblue};
  color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-top: 0;
  align-items: flex-start;

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
    margin: -50px auto ${theme.scale('80px')};

    &:hover {
      .PlusIcon {
        transform: rotate(180deg) scale(0.85);
      }
    }
  }

  .HeroArticle__Plus {
    width: 30px;
    height: 30px;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.sm}) {
      width: 42px;
      height: 42px;
    }

    .cls-1,
    rect {
      fill: white;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      width: ${theme.scale('54px')};
      height: ${theme.scale('54px')};
      margin-top: ${theme.scale('30px')};
    }
  }
`;

export const HeroArticleTextContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 25px;

  @media (min-width: ${theme.breakpoints.md}) {
    padding: ${theme.scale('40px 60px 40px 40px')};
  }

  @media (min-width: ${theme.breakpoints.md}) {
    width: 60%;
  }

  .HeroArticle__Date {
    font-family: ${theme.fontFamily.secondary};
    text-transform: uppercase;
  }

  .HeroArticle__Title {
    font-family: ${theme.fontFamily.tertiary};
    font-size: ${theme.fonts.xl_small};
    line-height: 32px;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin-bottom: 20px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: ${theme.scale('40px')};
      font-size: ${theme.scale(theme.fonts.xxl_small, 0.3)};
      line-height: ${theme.scale('35px')};
    }
  }

  .HeroArticle_Text {
    line-height: 28px;

    @media (min-width: ${theme.breakpoints.md}) {
      line-height: ${theme.scale('41px')};
      font-size: ${theme.fonts.l};
    }

    a {
      color: white;
      text-decoration: underline;
      font-weight: normal;
    }
  }
`;
