import React, { useContext } from 'react';
import {
  HeroArticleContainer,
  HeroArticleTextContainer,
} from './ArticleSection.css';
import Img from 'gatsby-image';
import Text from '../../Text/Text';
import Title from '../../Title/Title';
import { Desktop, Mobile } from '../../Media/Media';
import { Plus } from '../../Icons/Plus';
import LocaleContext from '../../../context/LocaleProvider';
import LocalizedLink from '../../LocalizedLink';

const HeroArticle = ({ query }) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const topPriorityQuery = query.node.data;
  const topPriorityUIDQuery = query.node.uid;

  const dateTopPriority = query ? new Date(query.node.data.date) : '';

  const monthTopPriorityArticle = dateTopPriority
    ? dateTopPriority.toLocaleString(i18n.locale, {
        month: 'short',
      })
    : '';

  const yearTopPriorityArticle = dateTopPriority
    ? dateTopPriority.getFullYear()
    : '';

  const imageDesktop = topPriorityQuery?.photo_vertical?.url.includes(
    '.gif'
  ) ? (
    <img
      src={topPriorityQuery?.photo_vertical?.url}
      style={{ width: '40%', height: 'auto' }}
    />
  ) : (
    <Img
      fluid={topPriorityQuery?.photo_vertical?.desktop?.childImageSharp?.fluid}
      style={{ width: '40%', height: 'auto' }}
    />
  );

  const imageMobile = topPriorityQuery?.photo_vertical?.url?.includes(
    '.gif'
  ) ? (
    <img
      src={topPriorityQuery?.photo_vertical?.url}
      style={{ width: '100%' }}
    />
  ) : (
    <Img
      fluid={topPriorityQuery?.photo_vertical?.mobile?.childImageSharp?.fluid}
      style={{ width: '100%' }}
    />
  );

  return (
    <HeroArticleContainer>
      <Desktop>{imageDesktop}</Desktop>
      <Mobile>{imageMobile}</Mobile>
      <HeroArticleTextContainer>
        <Text customClassName='HeroArticle__Date'>{`${monthTopPriorityArticle} ${yearTopPriorityArticle}`}</Text>

        <Title customClassName='HeroArticle__Title'>
          {topPriorityQuery?.title?.text}
        </Title>
        <Text
          as='div'
          type='teriary-xl'
          customClassName='HeroArticle_Text'
          dangerouslySetInnerHTML={{ __html: topPriorityQuery?.excerpt?.html }}
        />
        <LocalizedLink to={`/${topPriorityUIDQuery}`}>
          <Plus className='HeroArticle__Plus PlusIcon' />
        </LocalizedLink>
      </HeroArticleTextContainer>
    </HeroArticleContainer>
  );
};

export default HeroArticle;
